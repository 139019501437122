import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { AppNotificationService } from './app-notification.service';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class UnitService implements ValidationService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private appNotificationService: AppNotificationService,
  ) {}

  loadData(): Observable<any> {
    const payload = {
      productModelType: true,
      productModel: true,
      product: true,
      resource: true,
      timeSetting: true,
      activeLanguage: true,
      userChangeLanguage: true,
      firstDayOfWeek: true,
      firstWeekInYear: true,
      dateFormat: true,
      timeFormat: true,
      viewDetailsOfMeeting: true,
      hidePrivateMeeting: true,
      timeTable: true,
      layout: true,
      roomNameFontSize: true,
      picture: true,
      displayOrientation: true,
      mountOrientation: true,
      arrow: true,
      unitDefaultColors: true,
      resourceType: true,
      currentEventFinish: true,
      kiosk: true,
      downloadPeriodScreenshot: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      productModel: true,
      productModelType: true,
      area: true,
      timeSetting: true,
      timeTable: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForFilterDetail(): Observable<any> {
    const payload = {
      serviceUnitType: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  multiedit(payload: any): Observable<any> {
    return this.postAction(CONSTANTS.entity.unit + CONSTANTS.api.multiEdit, payload);
  }

  unregister(payload: any): Observable<any> {
    return this.postAction(CONSTANTS.entity.unit + CONSTANTS.api.unregister, payload);
  }

  copy(id: any, payload: any): Observable<any> {
    return this.postAction(CONSTANTS.entity.unit + '/' + id + CONSTANTS.api.copy, payload);
  }

  dashboardData(): Observable<any> {
    return this.getAction(CONSTANTS.entity.unit + CONSTANTS.api.dashboardData);
  }

  isUniqueName(name: string, id: number): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/unit/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  uploadFirmwares(firmwaresData): Observable<any> {
    const url = this.configService.value.apiURL + '/api/unit/uploadFirmwares';
    const formData = new FormData();
    firmwaresData.forEach((f) => {
      formData.append('firmware', f);
    });
    return this.http.post(url, formData).pipe(
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  detail(ids: number[]): Observable<any> {
    const url = this.configService.value.apiURL + '/api/unit/detail';
    return this.http.post(url, ids).pipe(
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  clearScreenshot(id: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/unit/clearScreenshot/' + id;
    return this.http.get(url).pipe(
      map((_) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  requestNowScreenshot(id: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/unit/requestNowScreenshot/' + id;
    return this.http.get(url).pipe(
      map((_) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  refresh(id: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/unit/refresh/' + id;
    return this.http.get(url).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  restart(id: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/unit/RestartUnit/' + id;
    return this.http.get(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  wakeUp(id: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/unit/WakeupUnit/' + id;
    return this.http.get(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  updateConfiguration(ids: number[]): Observable<any> {
    const url = this.configService.value.apiURL + '/api/unit/updateConfiguration';
    return this.http.post(url, ids).pipe(
      map((_) => ({ success: true })),
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: x.error,
        });
      }),
    );
  }

  getDropdownData(dropdownDataName: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/unit' + '/' + dropdownDataName;
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }
  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => {
        return x;
      }),
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}
