import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  public entity = CONSTANTS.entity.event;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
    private appNotificationService: AppNotificationService,
  ) {}

  getRawEvents(): Observable<any> {
    return this.getAction(this.entity + '/getRawEvents');
  }

  getRawEventsByResource(): Observable<any> {
    return this.getAction(this.entity + '/getRawEventsByResource');
  }

  clearCache() {
    const url = `${this.configService.value.apiURL}` + '/api/cache';
    return this.http.delete(url).pipe(
      map(() => ({
        success: true,
        data: null,
      })),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }

  clearAllCache() {
    const url = `${this.configService.value.apiURL}` + '/api/cache/ClearDataCache';
    return this.http.delete(url).pipe(
      map(() => ({
        success: true,
        data: null,
      })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  eventRepair(payload: { startDateTime: Date; endDateTime: Date; connectionAgentId: number }): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/EventRepair';
    return this.http.post(url, payload).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getEventRepairStatus(): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/EventRepairStatus';
    return this.http.get(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getProjectData(): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + '/project/debugData';
    return this.http.get(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, payload).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}
