import { createReducer, on } from '@ngrx/store';
import { ProjectsRoot } from './projects-root';
import {
  addNewExpandedInstanceId,
  addNewProjectInstance,
  addNewQrCodeAndObjectPermissions,
  applyNewInstanceTemplate,
  deleteProjectInstance,
  projectInstanceChanged,
  projectInstancesLoaded,
  projectInstanceUnitFlatObjectChanged,
  projectInstanceUnitIdChanged,
  projectInstanceUnitImportObjects,
  projectInstanceUnitImportParameters,
  projectInstanceUnitObjectAddQRCodes,
  projectInstanceUnitObjectChanged,
  projectInstanceUnitParameterChanged,
  projectRefreshStatuses,
  projectSelected,
  projectTabSelected,
  qrCodeAndObjectValueChanged,
  removeQrCodeAndObjectPermissions,
  setExpandedInstanceIds,
  setPermissions,
  setProjectInstances,
  setProjectInstanceUnitFlatObjects,
} from './projects.actions';
import { QrCodeProjectInstanceUnitObject, UnitObjectShow, UnitObjectType, ProjectTab, ProjectDescriptionObjectFlat } from '../models';

const initialState: ProjectsRoot = {
  projectId: undefined,
  instances: [],
  expandedInstanceIds: [],
  permissions: [],
  selectedTab: ProjectTab.General,
  objectDescriptions: [],
};

export enum ProjectInstanceStatus {
  Unregistered = 'unregistered',
  Unassigned = 'unassigned',
  Offline = 'offline',
  Online = 'online',
}

const _stateReducer = createReducer(
  initialState,
  on(projectSelected, (state, { projectId }) => ({ ...state, projectId: projectId })),
  on(projectInstancesLoaded, (state, action) => ({
    ...state,
    instances: action.instances,
    permissions: action.permissions,
  })),
  on(setProjectInstances, (state, { instances }) => ({ ...state, instances: instances })),
  on(projectRefreshStatuses, (state, { units }) => {
    const instances = [...state.instances];
    instances.forEach((instance) => {
      instance.units.forEach((instanceUnit) => {
        const foundUnit = units.find((x) => x.id === instanceUnit.unitId);
        if (foundUnit) {
          instanceUnit.status = foundUnit.unregistered
            ? ProjectInstanceStatus.Unregistered
            : foundUnit.online
              ? ProjectInstanceStatus.Online
              : ProjectInstanceStatus.Offline;
        } else {
          instanceUnit.status = ProjectInstanceStatus.Unassigned;
        }
      });
      instance.status = instance.units.some((x) => x.status === ProjectInstanceStatus.Unassigned)
        ? ProjectInstanceStatus.Unassigned
        : instance.units.some((x) => x.status === ProjectInstanceStatus.Unregistered)
          ? ProjectInstanceStatus.Unregistered
          : instance.units.some((x) => x.status === ProjectInstanceStatus.Offline)
            ? ProjectInstanceStatus.Offline
            : ProjectInstanceStatus.Online;
    });
    return { ...state, instances: instances };
  }),
  on(addNewProjectInstance, (state, { instance }) => ({ ...state, instances: [instance, ...state.instances] })),
  on(deleteProjectInstance, (state, { instanceId }) => {
    let newPermissions = [...state.permissions];
    state.instances.forEach((instance) => {
      instance.units.forEach((unit) => {
        if (unit.objects) {
          const objectIds = unit.objects.map((x) => x.id);
          newPermissions = newPermissions.filter((x) => !objectIds.includes(x.objectId));
        }
      });
    });
    return { ...state, instances: state.instances.filter((x) => x.id !== instanceId), permissions: newPermissions };
  }),
  on(applyNewInstanceTemplate, (state, { template, unitProductModels }) => {
    const projectInstances = [...state.instances];
    let newPermissions = [...state.permissions];
    const instanceUnitNames = template.map((x) => x.instanceName);
    projectInstances.forEach((projectInstance) => {
      projectInstance.units = projectInstance.units.filter((x) => instanceUnitNames.includes(x.name));
      template.forEach((templateUnit) => {
        const projectInstanceUnit = projectInstance.units.find((x) => x.name === templateUnit.instanceName);
        const productModel = unitProductModels.find((x) => x.csCode === templateUnit.csCode);
        if (projectInstanceUnit) {
          // cue unit data
          if (projectInstanceUnit.productModelId !== productModel.id) {
            projectInstanceUnit.unitId = null;
            projectInstanceUnit.status = 'unassigned';
          }
          projectInstanceUnit.productModelId = productModel.id;
          projectInstanceUnit.productCode = productModel.code;
          projectInstanceUnit.productModelName = productModel.name;
          // parameters
          if (templateUnit.parameters) {
            if (projectInstanceUnit.parameters) {
              const parameterNames = templateUnit.parameters.map((x) => x.name);
              projectInstanceUnit.parameters = projectInstanceUnit.parameters.filter((x) => parameterNames.includes(x.name));
              templateUnit.parameters.forEach((templateUnitParameter) => {
                const projectInstanceUnitParameter = projectInstanceUnit.parameters.find((x) => x.name === templateUnitParameter.name);
                if (!projectInstanceUnitParameter) {
                  projectInstanceUnit.parameters.push({
                    id: crypto.randomUUID(),
                    name: templateUnitParameter.name,
                    objectName: templateUnitParameter.objectName,
                    propertyName: templateUnitParameter.propertyName,
                    dataType: templateUnitParameter.dataType,
                    defaultValue: templateUnitParameter.defaultValue,
                    propertyGroup: templateUnitParameter.propertyGroup,
                    editMode: templateUnitParameter.editMode,
                    value: null,
                    useDefault: false,
                  });
                } else {
                  projectInstanceUnitParameter.name = templateUnitParameter.name;
                  projectInstanceUnitParameter.objectName = templateUnitParameter.objectName;
                  projectInstanceUnitParameter.propertyName = templateUnitParameter.propertyName;
                  projectInstanceUnitParameter.dataType = templateUnitParameter.dataType;
                  projectInstanceUnitParameter.defaultValue = templateUnitParameter.defaultValue;
                  projectInstanceUnitParameter.propertyGroup = templateUnitParameter.propertyGroup;
                  projectInstanceUnitParameter.editMode = templateUnitParameter.editMode;
                }
              });
            } else {
              projectInstanceUnit.parameters = templateUnit.parameters?.map((parameter) => ({
                id: crypto.randomUUID(),
                name: parameter.name,
                objectName: parameter.objectName,
                propertyName: parameter.propertyName,
                dataType: parameter.dataType,
                defaultValue: parameter.defaultValue,
                propertyGroup: parameter.propertyGroup,
                editMode: parameter.editMode,
                value: null,
                useDefault: false,
              }));
            }
          } else {
            projectInstanceUnit.parameters = undefined;
          }
          // objects
          if (templateUnit.objects) {
            if (projectInstanceUnit.objects) {
              const objectNames = templateUnit.objects.map((x) => x.name);
              projectInstanceUnit.objects = projectInstanceUnit.objects.filter((x) => objectNames.includes(x.name));
              newPermissions = newPermissions.filter(
                (p) =>
                  p.projectInstanceUnitId !== projectInstanceUnit.id ||
                  (p.projectInstanceUnitId === projectInstanceUnit.id && objectNames.includes(p.objectName)),
              );
              templateUnit.objects.forEach((templateUnitObject) => {
                const projectInstanceUnitObject = projectInstanceUnit.objects.find((x) => x.name === templateUnitObject.name);
                if (!projectInstanceUnitObject) {
                  projectInstanceUnit.objects.push({
                    id: crypto.randomUUID(),
                    name: templateUnitObject.name,
                    type: templateUnitObject.type,
                    qrCodeIds: [],
                  });
                } else {
                  projectInstanceUnitObject.name = templateUnitObject.name;
                  projectInstanceUnitObject.type = templateUnitObject.type;
                  const perm = newPermissions.find(
                    (p) => p.projectInstanceUnitId === projectInstanceUnit.id && p.objectName === templateUnitObject.name,
                  );
                  if (perm) {
                    perm.objectName = templateUnitObject.name;
                    perm.objectDisplayName = templateUnitObject.type + ' ' + templateUnitObject.name;
                  }
                }
              });
            } else {
              projectInstanceUnit.objects = templateUnit.objects?.map((object) => ({
                id: crypto.randomUUID(),
                name: object.name,
                type: object.type,
                qrCodeIds: [],
              }));
            }
          } else {
            projectInstanceUnit.objects = undefined;
            newPermissions = newPermissions.filter((p) => p.projectInstanceUnitId !== projectInstanceUnit.id);
          }
        } else {
          projectInstance.units.push({
            name: templateUnit.instanceName,
            id: crypto.randomUUID(),
            unitId: null,
            productModelId: productModel.id,
            productCode: productModel.code,
            productModelName: productModel.name,
            status: 'unassigned',
            parameters: templateUnit.parameters?.map((parameter) => ({
              id: crypto.randomUUID(),
              name: parameter.name,
              objectName: parameter.objectName,
              propertyName: parameter.propertyName,
              dataType: parameter.dataType,
              defaultValue: parameter.defaultValue,
              propertyGroup: parameter.propertyGroup,
              editMode: parameter.editMode,
              value: null,
              useDefault: false,
            })),
            objects: templateUnit.objects?.map((object) => ({
              id: crypto.randomUUID(),
              name: object.name,
              type: object.type,
              qrCodeIds: [],
            })),
          });
        }
      });
    });
    // object description
    const newObjectDescriptions: ProjectDescriptionObjectFlat[] = [];
    if (template.some((x) => x.objects && x.objects.length)) {
      template.forEach((templateCueUnit) => {
        if (templateCueUnit.objects) {
          templateCueUnit.objects.forEach((templateUnitObject) => {
            const currentObjDesc = state.objectDescriptions.find(
              (obj) => obj.cueUnitName === templateCueUnit.instanceName && obj.objectName === templateUnitObject.name,
            );
            newObjectDescriptions.push({
              objectName: templateUnitObject.name,
              cueUnitName: templateCueUnit.instanceName,
              collectAnalytics: currentObjDesc?.collectAnalytics ?? false,
              rank: currentObjDesc?.rank ?? 100,
            } as ProjectDescriptionObjectFlat);
          });
        }
      });
    }
    return { ...state, instances: [...projectInstances], permissions: [...newPermissions], objectDescriptions: [...newObjectDescriptions] };
  }),
  on(projectInstanceChanged, (state, { value, instanceId }) => {
    const instance = state.instances.find((x) => x.id === instanceId);
    if (instance) {
      instance.name = value.name ?? instance.name;
      instance.developerMode = value.developerMode ?? instance.developerMode;
      return { ...state, instances: [...state.instances] };
    }
    return { ...state };
  }),
  on(projectInstanceUnitIdChanged, (state, { unitId, instanceId, instanceUnitId, units }) => {
    const unit = units.find((x) => x.id === unitId);
    const oldInstances = [...state.instances];
    const instance = oldInstances.find((x) => x.id === instanceId);
    if (instance && instance.units && instance.units.length) {
      const instanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (instanceUnit) {
        instanceUnit.unitId = unitId;
        instanceUnit.status = unit ? (!unit.unregistered ? (unit.online ? 'online' : 'offline') : 'unregistered') : 'unassigned';

        instance.status = instance.units.some((x) => x.status === 'unassigned')
          ? 'unassigned'
          : instance.units.some((x) => x.status === 'unregistered')
            ? 'unregistered'
            : instance.units.some((x) => x.status === 'offline')
              ? 'offline'
              : 'online';
        return { ...state, instances: [...state.instances] };
      }
    }
    return { ...state };
  }),
  on(projectInstanceUnitParameterChanged, (state, { value, instanceId, instanceUnitId, parameterName, useDefault }) => {
    const newState = { ...state };
    const instance = newState.instances.find((x) => x.id === instanceId);
    if (instance && instance.units && instance.units.length) {
      const instanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (instanceUnit && instanceUnit.parameters && instanceUnit.parameters.length) {
        const parameter = instanceUnit.parameters.find((x) => x.name === parameterName);
        if (parameter) {
          parameter.value = value;
          parameter.useDefault = useDefault;
          if (useDefault) parameter.value = null;
          return { ...state, instances: [...newState.instances] };
        }
      }
    }
    return { ...state };
  }),
  on(projectInstanceUnitObjectChanged, (state, { qrCodeIds, instanceId, instanceUnitId, objectId }) => {
    const newState = { ...state };
    const instance = newState.instances.find((x) => x.id === instanceId);
    if (instance && instance.units && instance.units.length) {
      const instanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (instanceUnit && instanceUnit.objects && instanceUnit.objects.length) {
        const object = instanceUnit.objects.find((x) => x.id === objectId);
        if (object) {
          object.qrCodeIds = qrCodeIds;
          return { ...state, instances: [...newState.instances] };
        }
      }
    }
    return { ...state };
  }),
  on(setExpandedInstanceIds, (state, { ids }) => ({ ...state, expandedInstanceIds: ids })),
  on(addNewExpandedInstanceId, (state, { id }) => ({ ...state, expandedInstanceIds: [...state.expandedInstanceIds, id] })),
  on(addNewQrCodeAndObjectPermissions, (state, { newItems }) => ({ ...state, permissions: [...newItems, ...state.permissions] })),
  on(removeQrCodeAndObjectPermissions, (state, { ids }) => ({
    ...state,
    permissions: state.permissions.filter((x) => !ids.includes(x.id)),
  })),
  on(qrCodeAndObjectValueChanged, (state, { id, value }) => {
    const qrCodeAndObject = state.permissions.find((x) => x.id === id);
    if (qrCodeAndObject) {
      qrCodeAndObject.grantAccessId = value.grantAccessId ?? qrCodeAndObject.grantAccessId;
      qrCodeAndObject.minutesBeforeEvent = value.minutesBeforeEvent ?? qrCodeAndObject.minutesBeforeEvent;
      qrCodeAndObject.minutesAfterEvent = value.minutesAfterEvent ?? qrCodeAndObject.minutesAfterEvent;
      qrCodeAndObject.userIds = value.userIds ?? qrCodeAndObject.userIds;
      qrCodeAndObject.userGroupIds = value.userGroupIds ?? qrCodeAndObject.userGroupIds;
      qrCodeAndObject.showInDetailIds = value.showInDetailIds ?? qrCodeAndObject.showInDetailIds;
      qrCodeAndObject.showInMapIds = value.showInMapIds ?? qrCodeAndObject.showInMapIds;
      qrCodeAndObject.showInResourceDetail = value.showInResourceDetail ?? qrCodeAndObject.showInResourceDetail;
      return { ...state, permissions: [...state.permissions] };
    }
    return { ...state };
  }),
  on(setPermissions, (state, { permissions }) => ({ ...state, permissions: permissions })),
  on(projectInstanceUnitObjectAddQRCodes, (state, { qrCodeIds, instanceUnitId, objectId }) => {
    const instances = [...state.instances];
    instances.forEach((instance) => {
      const projectInstanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (projectInstanceUnit) {
        const unitObject = projectInstanceUnit.objects.find((x) => x.id === objectId);
        if (unitObject) {
          unitObject.qrCodeIds = unitObject.qrCodeIds.concat(qrCodeIds);
        }
      }
    });
    return { ...state, instances: [...instances] };
  }),
  on(projectInstanceUnitImportParameters, (state, { id, row, useDefaultTranslation }) => {
    const instances = [...state.instances];
    for (const instance of instances) {
      const cueUnit = instance.units.find((x) => x.id === id);
      if (cueUnit) {
        cueUnit.parameters?.forEach((parameter) => {
          parameter.value = row[parameter.objectName + ' ' + parameter.propertyName] ?? null;
          const useDefault = row[useDefaultTranslation + ' ' + parameter.objectName + ' ' + parameter.propertyName];
          parameter.useDefault = useDefault;
          if (useDefault) parameter.value = null;
        });
        break;
      }
    }
    return { ...state, instances: [...instances] };
  }),
  on(projectInstanceUnitImportObjects, (state, { id, row, qrCodes, unitObjectShow }) => {
    const instances = [...state.instances];
    let newPermissions = [...state.permissions];
    for (const instance of instances) {
      const cueUnit = instance.units.find((x) => x.id === id);
      if (cueUnit) {
        cueUnit.objects?.forEach((obj) => {
          const qrCodeUniqueNamesRow = row[obj.type + ' ' + obj.name];
          const qrCodeUniqueNames = qrCodeUniqueNamesRow?.split(';') ?? '';
          const selectedQrCodes = qrCodes.filter((x) => qrCodeUniqueNames.includes(x.uniqueName));
          obj.qrCodeIds = selectedQrCodes.map((x) => x.id);
          newPermissions = newPermissions.filter(
            (perm) => perm.objectId !== obj.id || (perm.objectId === obj.id && obj.qrCodeIds.includes(perm.qrCodeId)),
          );
          selectedQrCodes.forEach((qrCode) => {
            if (!newPermissions.find((x) => x.objectId === obj.id && x.qrCodeId === qrCode.id)) {
              newPermissions.push({
                id: crypto.randomUUID(),
                qrCodeId: qrCode.id,
                objectId: obj.id,
                objectName: obj.name,
                objectType: obj.type,
                objectDisplayName: obj.type + ' ' + obj.name,
                productModelId: cueUnit.productModelId,
                projectInstanceUnitName: cueUnit.name,
                projectInstanceUnitId: cueUnit.id,
                unitId: cueUnit.unitId,
                grantAccessId: 6,
                minutesBeforeEvent: 0,
                minutesAfterEvent: 0,
                userIds: [],
                userGroupIds: [],
                showInDetailIds: unitObjectShowIds(obj.type, unitObjectShow),
                showInMapIds: unitObjectShowIds(obj.type, unitObjectShow),
                areaId: qrCode.areaId,
              } as QrCodeProjectInstanceUnitObject);
            }
          });
        });
        break;
      }
    }
    return { ...state, instances: [...instances], permissions: [...newPermissions] };
  }),
  on(projectTabSelected, (state, { tab }) => ({ ...state, selectedTab: tab })),
  on(setProjectInstanceUnitFlatObjects, (state, { projectDescriptionUnits }) => {
    const newObjectDescriptions: ProjectDescriptionObjectFlat[] = [];
    projectDescriptionUnits.forEach((templateCueUnit) => {
      if (templateCueUnit.objects) {
        templateCueUnit.objects.forEach((templateUnitObject) => {
          newObjectDescriptions.push({
            objectName: templateUnitObject.name,
            cueUnitName: templateCueUnit.instanceName,
            collectAnalytics: templateUnitObject.collectAnalytics ?? false,
            rank: templateUnitObject.rank ?? 100,
          } as ProjectDescriptionObjectFlat);
        });
      }
    });
    return { ...state, objectDescriptions: [...newObjectDescriptions] };
  }),
  on(projectInstanceUnitFlatObjectChanged, (state, { flatObjectDescription }) => {
    const objDescriptions = [...state.objectDescriptions];
    const foundObjDescription = objDescriptions.find(
      (obj) => obj.cueUnitName === flatObjectDescription.cueUnitName && obj.objectName === flatObjectDescription.objectName,
    );
    if (foundObjDescription) {
      foundObjDescription.rank = flatObjectDescription.rank;
      foundObjDescription.collectAnalytics = flatObjectDescription.collectAnalytics;
      return { ...state, objectDescriptions: [...objDescriptions] };
    }
    return { ...state };
  }),
);

export function projectsReducer(state: any, action: any) {
  return _stateReducer(state, action);
}

function unitObjectShowIds(objectType: string, unitObjectShow: UnitObjectShow[]) {
  if (objectType.toUpperCase().includes(UnitObjectType.Control.toUpperCase())) {
    return unitObjectShow.filter((x) => x.control).map((x) => x.id);
  } else if (objectType.toUpperCase().includes(UnitObjectType.Monitor.toUpperCase())) {
    return unitObjectShow.filter((x) => x.monitor).map((x) => x.id);
  } else if (objectType.toUpperCase().includes(UnitObjectType.Indicator.toUpperCase())) {
    return unitObjectShow.filter((x) => x.indicator).map((x) => x.id);
  }
  return [];
}
