import { computed, Directive, EventEmitter, Input, Output, signal } from '@angular/core';
import { GridFilterConfig } from '@cue/admin-shared';
import { DataResult } from '@progress/kendo-data-query';
import { DataStateChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';

@Directive()
export class GridFilterDirective {
  @Input({ required: true }) gridState: DataStateChangeEvent = null;
  @Output() gridStateChanged: EventEmitter<DataStateChangeEvent> = new EventEmitter();
  public gridFilterConfig = signal<GridFilterConfig[]>([]);
  public activeFilterCount = computed<number>(() => this.gridFilterConfig().filter((x) => x.value).length);
  public gridView = signal<DataResult>(undefined);
  public readonly pagerSettings: PagerSettings = { pageSizes: [10, 50, 100] };

  protected updateGridFilterConfig(gridFilters: FilterDescriptor[]) {
    if (gridFilters) {
      this.gridFilterConfig.update((config) =>
        config.map((item) => {
          const filter = gridFilters.find((x: FilterDescriptor) => x.field === item.id);
          if (filter) {
            return { ...item, value: filter.value };
          }
          return item;
        }),
      );
    }
  }

  public onGridStateChange(event: DataStateChangeEvent) {
    this.gridStateChanged.emit({ ...event, filter: this.gridState.filter });
  }

  public onClearFilters() {
    this.gridFilterConfig.update((config) => config.map((item) => ({ ...item, value: null })));
    this.gridStateChanged.emit({ ...this.gridState, skip: 0, filter: undefined });
  }

  protected onFilterGrid(value: number | string, filterName: string) {
    const filters = [];
    this.gridFilterConfig.update((config) =>
      config.map((item) => {
        if (item.id === filterName) {
          if (value) {
            filters.push({
              field: item.id,
              operator: 'eq',
              value: value,
            });
          }
          return { ...item, value: value };
        }
        if (item.value) {
          filters.push({
            field: item.id,
            operator: 'eq',
            value: item.value,
          });
        }
        return item;
      }),
    );
    this.gridStateChanged.emit({
      ...this.gridState,
      skip: 0,
      filter: {
        logic: 'and',
        filters,
      },
    } as DataStateChangeEvent);
  }
}
