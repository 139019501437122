export interface ProjectDescriptionUnit {
  instanceName: string;
  csCode: string;
  parameters: ProjectDescriptionParameter[];
  objects: ProjectDescriptionObject[];
}

export interface ProjectDescriptionParameter {
  name: string;
  objectName: string;
  propertyName: string;
  dataType: string;
  defaultValue: string;
  propertyGroup: string;
  editMode: string;
  useDefault: boolean;
}

export interface ProjectDescriptionObject {
  name: string;
  type: string;
  collectAnalytics: boolean;
  rank: number;
}

export interface ProjectInstance {
  id: string;
  name: string;
  status: string;
  developerMode: boolean;
  units: ProjectInstanceUnit[];
}

export interface ProjectInstanceUnit {
  id: string;
  name: string;
  productModelId: number;
  productModelName: string;
  productCode: string;
  unitId: number;
  parameters: ProjectInstanceUnitParameter[];
  status: string;
  objects: ProjectInstanceUnitObject[];
}

export interface ProjectInstanceUnitParameter extends ProjectDescriptionParameter {
  id: string;
  value: string;
}

export interface ProjectInstanceUnitObject {
  id: string;
  name: string;
  type: string;
  qrCodeIds: string[];
}

export interface UnitDropdown {
  id: number;
  name: string;
  productModelId: number;
  isAssigned: boolean;
  ipAddress: string;
  unregistered: boolean;
  online: boolean;
}

export interface QrCodeProjectInstanceUnitObject {
  id: string;
  objectId: string;
  qrCodeId: string;
  // filter
  objectName: string;
  objectType: string;
  objectDisplayName: string;
  projectInstanceUnitName: string;
  projectInstanceUnitId: string;
  productModelId: number;
  unitId: number;
  areaId: number;
  resourceName: string;
  // permissions
  grantAccessId: number;
  minutesBeforeEvent: number;
  minutesAfterEvent: number;
  userIds: number[];
  userGroupIds: string[];
  showInDetailIds: number[];
  showInMapIds: number[];
  showInResourceDetail: boolean;
}

export interface ProjectInstanceUnitFlat {
  id: string;
  name: string;
  unitId: number;
  productModelId: number;
  projectInstanceId: string;
  projectInstanceName: string;
  parameters: ProjectInstanceUnitParameter[];
  objects: ProjectInstanceUnitObject[];
}

export enum ReloadStatus {
  None = 'none',
  Import = 'import',
  Save = 'save',
}

export interface UnitObjectShow {
  id: number;
  name: string;
  control: boolean;
  monitor: boolean;
  indicator: boolean;
}

export enum UnitObjectType {
  Control = 'control',
  Monitor = 'monitor',
  Indicator = 'indicator',
}

export enum ProjectTab {
  General = 'general',
  Instances = 'instances',
  Parameters = 'parameters',
  Objects = 'objects',
  Permissions = 'permissions',
  Assigment = 'assigment',
}

export interface ProjectQRCode {
  id: string;
  name: string;
  code: string;
  uniqueName: string;
  areaId: number;
  resourceName: string;
}

export interface ProjectDescriptionObjectFlat {
  cueUnitName: string;
  objectName: string;
  collectAnalytics: boolean;
  rank: number;
}
